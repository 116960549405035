import { Box } from '@material-ui/core';
// import Logo from './Logo';
import Logo from '../images/flexvertical.png';

const SlashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: '#262B40',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    {/* <Logo /> */}
    <img
                                src={Logo}
                                alt="ScanAware Logo"
                                height="80"
                            />
  </Box>
);

export default SlashScreen;

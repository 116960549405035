import React from 'react';

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import {
  ButtonGroup, Autocomplete, Link, Divider, Box, Button, Container, Card, Grid, Switch, TextField, Typography,

  Table,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TableRow,

} from '@material-ui/core';
import axios from 'axios';
import Trash from '../../icons/Trash';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import { parseJSON } from 'date-fns';
class ReportConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDataId: "",
      deleteConfirmationShouldOpen: false,
      emailTime: { label: "11:00 pm", value: "23" },
      days: "",
      email: "",
      arrayEmails: [],
      selectedEmailId: ""
    }
    this.araysDays = [
      "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
    ]
    this.arrayTime = [
      { label: "12:00 am", value: "0" },
      { label: "12:30 am", value: "0.5" },
      { label: "01:00 am", value: "1" },
      { label: "01:30 am", value: "1.5" },
      { label: "02:00 am", value: "2" },
      { label: "02:30 am", value: "2.5" },
      { label: "03:00 am", value: "3" },
      { label: "03:30 am", value: "3.5" },
      { label: "04:00 am", value: "4" },
      { label: "04:30 am", value: "4.5" },
      { label: "05:00 am", value: "5" },
      { label: "05:30 am", value: "5.5" },
      { label: "06:00 am", value: "6" },
      { label: "06:30 am", value: "6.5" },
      { label: "07:00 am", value: "7" },
      { label: "07:30 am", value: "7.5" },
      { label: "08:00 am", value: "8" },
      { label: "08:30 am", value: "8.5" },
      { label: "09:00 am", value: "9" },
      { label: "09:30 am", value: "9.5" },
      { label: "10:00 am", value: "10" },
      { label: "10:30 am", value: "10.5" },
      { label: "11:00 am", value: "11" },
      { label: "11:30 am", value: "11.5" },
      { label: "12:00 pm", value: "12" },
      { label: "12:30 pm", value: "12.5" },
      { label: "01:00 pm", value: "13" },
      { label: "01:30 pm", value: "13.5" },
      { label: "02:00 pm", value: "14" },
      { label: "02:30 pm", value: "14.5" },
      { label: "03:00 pm", value: "15" },
      { label: "03:30 pm", value: "15.5" },
      { label: "04:00 pm", value: "16" },
      { label: "04:30 pm", value: "16.5" },
      { label: "05:00 pm", value: "17" },
      { label: "05:30 pm", value: "17.5" },
      { label: "06:00 pm", value: "18" },
      { label: "06:30 pm", value: "18.5" },
      { label: "07:00 pm", value: "19" },
      { label: "07:30 pm", value: "19.5" },
      { label: "08:00 pm", value: "20" },
      { label: "08:30 pm", value: "20.5" },
      { label: "09:00 pm", value: "21" },
      { label: "09:30 pm", value: "21.5" },
      { label: "10:00 pm", value: "22" },
      { label: "10:30 pm", value: "22.5" },
      { label: "11:00 pm", value: "23" },
      { label: "11:30 pm", value: "23.5" }

    ]

  }

  componentDidMount() {
    if (this.props.location.state != null) {
      const { _id } = this.props.location.state
      console.log(_id)
      if (_id != undefined) {
        this.setState({
          selectedDataId: _id
        }, () => {
          this.getData()
        })
      }
    }
  }
  getData() {
    // this.setStateToDefault()
    this.setState({ isLoading: true })
    axios.get('/apis/reportconfiguration/details/' + this.state.selectedDataId).then((res) => {
      if (res.data.success) {
        this.setState({
          days: res.data.data.days,
          emailTime: this.arrayTime.filter((stTime) => res.data.data.emailTime == stTime.label)[0],
          arrayEmails: res.data.data.emails,
          email: "",
          isLoading: false,
          deleteConfirmationShouldOpen: false
        })
      }
    }).catch(function (error) {
      console.log(error);
    });
  }
  apiCalling = async () => {

    if (this.state.selectedDataId != "") {
      return await axios.put('/apis/reportconfiguration/' + this.state.selectedDataId, {
        days: this.state.days,
        emailTime: this.state.emailTime.label
      })
    }

  }
  addEmail = async () => {

    if (this.state.selectedDataId != "") {
      return await axios.post('/apis/reportconfiguration/addemail/' + this.state.selectedDataId, {
        email: this.state.email
      })
    }

  }
  deleteConfirmation = (e) => {
    
    this.setState({
      selectedEmailId: e,
      deleteConfirmationShouldOpen: true
    })
  }
  updateConfirmAlert = (value) => {
    //console.log("ID:", value, this.state.selectedDataId)
    if (value) {
      this.deleteEmail()
    } else {
      this.setState({ deleteConfirmationShouldOpen: false })
    }
  }
  deleteEmail = () =>{
    
    this.delete()
    
  }
  delete(){
    axios.post('/apis/reportconfiguration/deleteemail/' + this.state.selectedDataId, {
      id: this.state.selectedEmailId
    }).then((res)=>{
      
      this.getData()
    }).catch( (error)=> {
      console.log(parseJSON(error));
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>ScanAware</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 0
          }}
        >
          <Container maxWidth={'xl'} >

            <Box >
              <Formik
                enableReinitialize
                initialValues={{
                  days: this.state.days,
                  emailTime: this.state.emailTime,
                  submit: null
                }}
                validationSchema={Yup
                  .object()
                  .shape({
                    days: Yup.string().max(255).required('day is required'),
                  })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                  try {
                    // NOTE: Make API request
                    // await wait(500);
                    this.setState({
                      days: values.days,
                      emailTime: values.emailTime,

                    })

                    var result = await this.apiCalling()
                    console.log(result)

                    if (result.data.success == 0) {

                      setStatus({ success: false });
                      // setErrors({ sensorID: result.data.message });
                      // this.setState({
                      //   updateConfirmationShouldOpen: false
                      // })
                      this.props.enqueueSnackbar(result.data.message, {
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'top'
                        },
                        variant: 'error'
                      });
                    } else {
                      resetForm();
                      setStatus({ success: true });
                      this.props.enqueueSnackbar("Configuration " + (this.state.selectedDataId == "" ? "added" : "updated"), {
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'top'
                        },
                        variant: 'success'
                      });
                      this.props.navigate(-1)
                    }
                    setSubmitting(false);
                  } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form
                    onSubmit={handleSubmit}
                    {...this.props}
                  >
                    <Card>
                      <Box sx={{ p: 3 }}>
                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <Typography variant="h5">
                              Report Time
                            </Typography>
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>
                              Days
                          </Typography>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                              {
                                this.araysDays.map((obj, inx) => {
                                  var isFound = false
                                  if (this.state.days != "") {
                                    isFound = this.state.days.split(",").filter((day) => day == obj).length > 0
                                  }
                                  return (<Button
                                    type="button"
                                    id="shiftDays"
                                    name="shiftDays"
                                    variant={isFound ? "contained" : ""}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      if (isFound) {
                                        var daysTemp = this.state.days.split(",").filter((day) => day != obj).join(",")
                                        // this.state.shiftDays = days
                                        this.setState({
                                          days: daysTemp
                                        })
                                      }
                                      else {
                                        var days = ""
                                        var tempDays = this.state.days + "," + obj

                                        if (this.state.shiftDays != "") {
                                          this.araysDays.map((subObj) => {
                                            tempDays.split(",").map((subDay) => {
                                              if (subObj == subDay) {
                                                days += subDay + ","
                                              }
                                            })
                                          })
                                        } else {
                                          days = obj + ","
                                        }
                                        // values.shiftDays = days.substr(0, days.length - 1)
                                        this.setState({
                                          days: days.substr(0, days.length - 1)
                                        })
                                      }

                                    }}
                                    key={inx}
                                  >{obj}</Button>)
                                })
                              }
                            </ButtonGroup>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <Autocomplete
                              value={values.emailTime}
                              getOptionLabel={(option) => option.label}
                              options={this.arrayTime}
                              getOptionSelected={(option, value) => option.label === value.label}

                              onChange={(event, newValue) => {
                                event.preventDefault()
                                // handleChange(newValue)
                                if (newValue != null) {
                                  this.setState({
                                    emailTime: newValue
                                  })
                                }

                              }}
                              onBlur={handleBlur}
                              // onChange={handleChange}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  error={Boolean(touched.emailTime && errors.emailTime)}
                                  helperText={touched.emailTime && errors.emailTime}
                                  label="Time"
                                  name="emailTime"
                                  variant="outlined"
                                  {...params}
                                />
                              )}
                            />
                          </Grid>


                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Button
                            color="primary"
                            variant="text"
                            type="reset"
                            onClick={(e) => {
                              this.props.navigate(-1)
                            }}
                          >
                            Cancel
                           </Button>
                          <Box sx={{ flexGrow: 1 }} />
                          <Button
                            color="primary"
                            sx={{ ml: 1 }}
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                          >
                            {this.state.selectedDataId == "" ? "Add" : "Update"}
                          </Button>
                        </Box>

                      </Box>
                    </Card>
                  </form>
                )}
              </Formik>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            mt: 3
          }}>
          <Container maxWidth={'xl'} >

            <Box >
              <Formik
                enableReinitialize
                initialValues={{
                  email: this.state.email,
                  submit: null
                }}
                validationSchema={Yup
                  .object()
                  .shape({
                    email: Yup.string().max(255).required('email is required'),
                  })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                  try {
                    // NOTE: Make API request
                    // await wait(500);
                    this.setState({
                      email: values.email
                    })

                    var result = await this.addEmail()
                    console.log(result)

                    if (result.data.success == 0) {

                      setStatus({ success: false });
                      // setErrors({ sensorID: result.data.message });
                      // this.setState({
                      //   updateConfirmationShouldOpen: false
                      // })
                      this.props.enqueueSnackbar(result.data.message, {
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'top'
                        },
                        variant: 'error'
                      });
                    } else {
                      resetForm();
                      setStatus({ success: true });
                      this.props.enqueueSnackbar("Email added", {
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'top'
                        },
                        variant: 'success'
                      });
                      this.getData()
                    }
                    setSubmitting(false);
                  } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form
                    onSubmit={handleSubmit}
                    {...this.props}
                  >
                    <Card>
                      <Box sx={{ p: 3 }}>
                        <Grid
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <Typography variant="h5">
                              Receivers
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.email && errors.email)}
                              fullWidth
                              helperText={touched.email && errors.email}
                              label="Email"
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onChange={(e) => {
                                handleChange(e)
                                this.setState({
                                  email: e.target.value
                                })
                              }}
                              value={values.email}
                              variant="outlined"
                            />

                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}

                          >
                            <Button

                              color="primary"
                              sx={{ ml: 1, mt: 1 }}
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                            >
                              Add
                          </Button>
                          </Grid>
                        </Grid>

                      </Box>
                    </Card>
                  </form>
                )}
              </Formik>
            </Box>
            <Card>
              <Table>
                <TableHead>
                  <TableRow>

                    <TableCell>
                      Email
                </TableCell>

                    <TableCell align="right">
                      Actions
                </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.arrayEmails.map((data) => {

                    return (
                      <TableRow
                        hover
                        key={data.id}
                      >

                        <TableCell>

                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {data.email}
                          </Typography>



                        </TableCell>
                        <TableCell align="right">

                          <IconButton
                            onClick={(e) => {
                              this.deleteConfirmation(data._id)
                            }}
                          >
                            <Trash fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          </Container>
          {this.state.deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
            updateConfirmAlert={(e, value) => {
              console.log(e, value)
              //alert("test")
              this.updateConfirmAlert(value)
            }} />)}
        </Box>
      </>
    );
  };
}

export default withSnackbar(ReportConfiguration);
import { Box, Table, TableRow, TableCell, TableHead, TableBody, Card, Typography, Collapse, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Scrollbar from '../../components/Scrollbar';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Label from 'src/components/Label';

function Row(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  // console.log("test",data)
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.totalTime}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.totalRunningTime}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.totalDownTime}
          </Typography>
        </TableCell>
        <TableCell>
          {/* <Typography
            color="textPrimary"
            variant="subtitle2"
          > */}
          <Label color={data.temperatureState == 1 ? "success" : "error"}>
            {data.temperatureState == 1 ? "Normal" : "Abnormal"}
            </Label>
          {/* </Typography> */}
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            <Label color={data.vibrationState == 1 ? "success" : "error"}>
            {data.vibrationState == 1 ? "Normal" : "Abnormal"}
            </Label>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.eventData.todayEvents}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ minWidth: 700 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ ml: 2 }}>
                By Temperature
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Current
                    </TableCell>
                    <TableCell>
                      Lowest
                    </TableCell>
                    <TableCell>
                      Highest
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    key={data._id + "T"}
                  >

                    <TableCell>

                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.temperatureData.currentTemperature}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.temperatureData.minTemperature}
                      </Typography>



                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.temperatureData.maxTemperature}
                      </Typography>

                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ minWidth: 700 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ ml: 2 }}>
                By Vibration - Acceleration
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      X Axis (mm/s)
                    </TableCell>
                    <TableCell>
                      Y Axis (mm/s)
                    </TableCell>
                    <TableCell>
                      Z Axis (mm/s)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    key={data._id + "T"}
                  >

                    <TableCell>

                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.lastSensObj.xAxis}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.lastSensObj.yAxis}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.lastSensObj.zAxis}
                      </Typography>

                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ minWidth: 700 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ ml: 2 }}>
                By Vibration - Crest Factor
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      X Axis
                    </TableCell>
                    <TableCell>
                      Y Axis
                    </TableCell>
                    <TableCell>
                      Z Axis
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    key={data._id + "T"}
                  >

                    <TableCell>

                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.lastSensCrestObj.crestX.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.lastSensCrestObj.crestY.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="textPrimary"
                        variant="subtitle2"
                      >
                        {data.lastSensCrestObj.crestZ.toFixed(2)}
                      </Typography>

                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ minWidth: 700 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ ml: 2 }}>
                By Events
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>

                    <TableCell>
                      Event ID
                    </TableCell>
                    <TableCell>
                      Timestamp
                    </TableCell>
                    <TableCell>
                      Type
                    </TableCell>
                    <TableCell>
                      Message
                    </TableCell>
                    <TableCell>
                      Data
                    </TableCell>
                    <TableCell>
                      Duration
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.machineEventData.map((eventData) => {
                    return (
                      <TableRow
                        hover
                        key={eventData._id}
                      >

                        <TableCell>

                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {eventData.eventID}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {eventData.startTime}
                          </Typography>



                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {eventData.type}
                          </Typography>

                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {eventData.message}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {eventData.data}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            color="textPrimary"
                            variant="subtitle2"
                          >
                            {eventData.totalTime}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const MachineOverviewPerformanceData = (props) => {
  const { arrayData, ...other } = props;
  const theme = useTheme();
  return (
    <Card {...other}>
      {/* <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      > */}

      <Box
        sx={{
          minHeight: '100%',

        }}
      >

        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Total Time
                  </TableCell>
                  <TableCell>
                    Runtime
                  </TableCell>
                  <TableCell>
                    Downtime
                  </TableCell>
                  <TableCell>
                    Temp
                  </TableCell>
                  <TableCell>
                    Vibration
                  </TableCell>
                  <TableCell>
                    Events
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arrayData.map((data) =>
                  <Row key={data._id} data={data} />
                )}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Box >
    </Card>
  );
}
export default MachineOverviewPerformanceData;

import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';

import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
// import UserManage from './views/User/UserManage';

import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import ShiftRevenueReportDetails from './views/Reports/ShiftRevenueReportDetails';
import MachineOverviewPerformanceDetails from './views/Reports/MachineOverviewPerformanceDetails';
import MachineRevenueReportDetails from './views/Reports/MachineRevenueReportDetails';





function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}
const Loadable1 = (Component) => (props) => (

  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Profile = Loadable(lazy(() => import('./views/User/Profile')));

// const UserList = Loadable(lazy(() => import('./views/User/UserList')));
// const UserManage = Loadable(lazy(() => import('./views/User/UserManage')));

// const CompanyList = Loadable(lazy(() => import('./views/Company/CompanyList')));
// const CompanyManage = Loadable(lazy(() => import('./views/Company/CompanyManage')));
const CompanyDetails = Loadable(lazy(() => import('./views/Company/CompanyDetails')));
const CompanyManage = Loadable(lazy(() => import('./views/Company/CompanyManage')));
const ShiftList = Loadable(lazy(() => import('./views/Company/ShiftList')));
const ShiftManage = Loadable(lazy(() => import('./views/Company/ShiftManage')));
const ReadersList = Loadable(lazy(() => import( './views/Company/ReadersList')));
const ReaderManage = Loadable(lazy(() => import( './views/Company/ReaderManage')));

const DowntimeList = Loadable(lazy(() => import( './views/Company/DowntimeList')));
const DowntimeManage = Loadable(lazy(() => import( './views/Company/DowntimeManage')));

const UserList = Loadable(lazy(() => import( './views/User/UserList')));
const UserManage = Loadable(lazy(() => import( './views/User/UserManage')));


const MachineList = Loadable(lazy(() => import( './views/Machine/MachineList')));
const MachineManage = Loadable(lazy(() => import( './views/Machine/MachineManage')));
const MachineDetails = Loadable(lazy(() => import( './views/Machine/MachineDetails')));
const SensorManage = Loadable(lazy(() => import( './views/Machine/SensorManage')));
const SensorDetails = Loadable(lazy(() => import( './views/Machine/SensorDetails')));
const ThresholdManage = Loadable(lazy(() => import( './views/Machine/ThresholdManage')));
const ReportList = Loadable(lazy(() => import( './views/Reports/ReportList')));
const MachineActivityEmailReportDetail = Loadable(lazy(() => import( './views/Reports/MachineActivityEmailReportDetail')));

const FloorView = Loadable(lazy(() => import( './views/FloorView/FloorView')));
const Events = Loadable(lazy(() => import( './views/Events/Events')));


const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

const ContractorsList = Loadable(lazy(() => import('./views/Company/ContractorsList')));
const ConstractorsManage = Loadable(lazy(() => import('./views/Company/ConstractorsManage')));

const WorkOrderList = Loadable(lazy(() => import('./views/WorkOrders/WorkOrderList')));
const WorkOrderManage = Loadable(lazy(() => import('./views/WorkOrders/WorkOrderManage')));
const WorkOrderDetails = Loadable(lazy(() => import( './views/WorkOrders/WorkOrderDetails')));


const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const CompanySiteList = Loadable(lazy(() => import('./views/CompanySites/CompanySiteList')));
const CompanyZoneList = Loadable(lazy(() => import('./views/CompanyZones/CompanyZoneList')));
const CompanySiteDetails = Loadable(lazy(() => import('./views/CompanySites/CompanySiteDetails')));
const CompanySiteManage = Loadable(lazy(() => import('./views/CompanySites/CompanySiteManage')));
const ZoneDetails = Loadable(lazy(() => import('./views/CompanySites/SubZones/ZoneDetails')));

const CompanyReadersList = Loadable(lazy(() => import('./views/CompanyReaders/CompanyReadersList')));
const CompanyReaderManage = Loadable(lazy(() => import('./views/CompanyReaders/CompanyReaderManage')));

const ProductGroupList = Loadable(lazy(() => import('./views/ProductGroup/ProductGroupList')));
const ProductGroupManage = Loadable(lazy(() => import('./views/ProductGroup/ProductGroupManage')));
const ProductGroupDetails = Loadable(lazy(() => import('./views/ProductGroup/ProductGroupDetails')));

const ZoneProcessesDetails = Loadable(lazy(() => import('./views/ZoneProcesses/ZoneProcessesDetails')));
const ActivityDetailsList = Loadable(lazy(() => import('./views/Activitys/ActivityDetailsList')));
const CompletedActivityList = Loadable(lazy(() => import('./views/Activitys/CompletedActivityList')));
const CompanySettings = Loadable(lazy(() => import('./views/Settings/CompanySettings')));
const PasswordSecurityForm = Loadable(lazy(() => import( './views/Security/PasswordSecurityForm')));
const LoginLogs = Loadable(lazy(() => import( './views/LoginLogs/LoginLogs')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/profile',
        element: <Profile />
      },
    ]
  },
  {
    path: 'activities',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <ActivityDetailsList />
      },
    ]
  },
  {
    path: 'finishedgoods',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <CompletedActivityList />
      },
    ]
  },
  {
    path: 'loginlogs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <LoginLogs />
      },
    ]
  },
  {
    path: 'passwordsecurity',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <PasswordSecurityForm />
      },
    ]
  },
  {
    path: 'reports',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <ReportList />
      },
      {
        path: '/shiftreport',
        element: <MachineActivityEmailReportDetail />
      },
      {
        path: '/machineperformancereport',
        element: <MachineRevenueReportDetails />
      },
      {
        path: '/shiftperformancereport',
        element: <ShiftRevenueReportDetails />
      },
      {
        path: '/machineoverviewperformancereport',
        element: <MachineOverviewPerformanceDetails />
      }
    ]
  },
  {
    path: 'floorview',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <FloorView />
      },
    ]
  },
  {
    path: 'events',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Events />
      },
    ]
  },
  {
    path: 'readers',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <ReadersList />
      },
      {
        path: '/manage',
        element: <ReaderManage />
      }
    ]
  },
  {
    path: 'workorders',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <WorkOrderList />
      },
      {
        path: '/manage',
        element: <WorkOrderManage />
      },
      {
        path: '/details',
        element: <WorkOrderDetails />
      },
    ]
  },
  {
    path: 'machines',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <MachineList />
      },
      {
        path: '/manage',
        element: <MachineManage />
      },
      {
        path: '/details',
        element: <MachineDetails />
      },
      {
        path: '/sensors/manage',
        element: <SensorManage />
      },
      {
        path: '/sensors/details',
        element: <SensorDetails />
      },
      {
        path: '/sensors/thresholds/manage',
        element: <ThresholdManage />
      }
    ]
  },
  {
    path: 'users',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <UserList />
      },
      {
        path: '/manage',
        element: <UserManage />
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
    ]
  },
  
  {
    path: 'company',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <CompanyDetails />
      },
      {
        path: '/companysites',
        element: <CompanySiteList />
      },
      {
        path: '/companyzones',
        element: <CompanyZoneList />
      },
      {
        path: '/zoneprocessesdetails',
        element: <ZoneProcessesDetails />
      },
      {
        path: '/companyzones/zonedetails',
        element: <ZoneDetails />
      },
      {
        path: '/companysites/details',
        element: <CompanySiteDetails />
      },
      {
        path: '/companysites/details/zonedetails',
        element: <ZoneDetails />
      },
      {
        path: '/companysites/manage',
        element: <CompanySiteManage />
      },
      {
        path: '/companyreaders',
        element: <CompanyReadersList />
      },
      {
        path: '/companyreaders/manage',
        element: <CompanyReaderManage />
      },
      {
        path: '/productgroup',
        element: <ProductGroupList />
      },
      {
        path: '/productgroup/manage',
        element: <ProductGroupManage />
      },
      {
        path: '/productgroup/details',
        element: <ProductGroupDetails />
      },
      {
        path: '/shifts',
        element: <ShiftList />
      },
      {
        path: '/shifts/manage',
        element: <ShiftManage />
      },
      {
        path: '/manage',
        element: <CompanyManage />
      },
      {
        path: '/downtimes/manage',
        element: <DowntimeManage />
      },
      {
        path: '/downtimes',
        element: <DowntimeList />
      },
      {
        path: '/contractors',
        element: <ContractorsList />
      },
      {
        path: '/contractors/manage',
        element: <ConstractorsManage />
      },
      {
        path: '/settings',
        element: <CompanySettings />
      },
    ]
  },
  {
    path: '/*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '/*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Alert, Box, Button, FormHelperText, TextField, Divider, Link } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';


const ResetAuth = (props) => {
  const isMountedRef = useIsMountedRef();
  const { resetPassword,logout } = useAuth();

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          oldPassword: Yup.string().required('Password is required'),
          newPassword: Yup.string().matches(props.regex.regex, props.regex.validationInfo).required('newPassword is required'),
          confirmPassword: Yup.string().matches(props.regex.regex, props.regex.validationInfo).required('Confirm Password is required').oneOf([Yup.ref('newPassword')], 'Password does not same')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          var data = await resetPassword(values.oldPassword, values.newPassword);
          console.log(data)
          if (data != undefined && data.success && isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          } else {
            setStatus({ success: false });
            setErrors({ submit: data.message });
            setSubmitting(false);
          }

        } catch (err) {
          console.log("inCATCH");
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField

            error={Boolean(touched.oldPassword && errors.oldPassword)}
            fullWidth
            helperText={touched.oldPassword && errors.oldPassword}
            label="Old Password"
            margin="normal"
            name="oldPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.oldPassword}
            variant="outlined"
          />
          <TextField

            error={Boolean(touched.newPassword && errors.newPassword)}
            fullWidth
            helperText={touched.newPassword && errors.newPassword}
            label="New Password"
            margin="normal"
            name="newPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.newPassword}
            variant="outlined"
          />
          <TextField

            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Confirm Password"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Verify
            </Button>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Link
            color="textSecondary"
            sx={{ mt: 0 }}
            onClick={(e) => {
              logout()
            }}
            variant="body2"
          >
            Go To Login
          </Link>
        </form>
      )}
    </Formik>
  );
};

export default ResetAuth;

import * as Yup from 'yup';
import { Formik } from 'formik';
import { Alert, Box, Button, FormHelperText, TextField, Divider, Link } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';


const ForgotPassForm = (props) => {
  const isMountedRef = useIsMountedRef();
  const { checkEmail } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup.string().email().required('E-Mail is required')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          var data = await checkEmail(values.email);
          console.log("checkEmail:",data)
          if (data != undefined && data.success && isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          } else {
            setStatus({ success: false });
            setErrors({ submit: data.message });
            setSubmitting(false);
          }

        } catch (err) {
          console.log("checkEmail:inCATCH");
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >

          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Registred EmailEmail"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPassForm;

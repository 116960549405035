import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Hidden, Link, Typography } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import MailIcon from '../../icons/Mail';
import ShareIcon from '../../icons/Share';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import MapIcon from '@material-ui/icons/Map';
import EventIcon from '@material-ui/icons/Event';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
const sections = [
  {
    title: 'General',
    roles:["Admin","SuperAdmin","Standard"],
    items: [
      {
        title: 'OVERVIEW',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'ACTIVITIES',
        path: '/activities',
        icon: <DonutSmallIcon fontSize="small" />,
      },
      // {
      //   title: 'FINISHED GOODS',
      //   path: '/finishedgoods',
      //   icon: <WorkIcon fontSize="small" />,
      // },
      {
        title: 'FLOOR VIEW',
        path: '/floorview',
        icon: <MapIcon fontSize="small" />
      },
      // {
      //   title: 'REPORTS',
      //   path: '/reports',
      //   icon: <EventIcon fontSize="small" />
      // },
      // {
      //   title: 'EVENTS',
      //   path: '/events',
      //   icon: <AlarmOnIcon fontSize="small" />
      // },
      // {
      //   title: 'WORKORDERS',
      //   path: '/workorders',
      //   icon: <WorkIcon fontSize="small" />
      // }
    ]
  },
  {
    title: 'Settings',
    roles:["Admin","SuperAdmin"],
    items: [
      {
        title: 'COMPANY',
        path: '/company',
        icon: <BusinessIcon fontSize="small" />,
        children: [
          {
            title: 'DETAILS',
            path: '/company'
          },
          {
            title: 'SHIFTS',
            path: '/company/shifts'
          },
          {
            title: 'SITES',
            path: '/company/companysites'
          },
          {
            title: 'ZONES',
            path: '/company/companyzones'
          },
          {
            title: 'PROCESS QUEUE',
            path: '/company/zoneprocessesdetails'
          },
          {
            title: 'PRODUCT GROUPS',
            path: '/company/productgroup'
          },
          {
            title: 'DEVICE READERS',
            path: '/company/companyreaders'
          },
          {
            title: 'DOWNTIME CODES',
            path: '/company/downtimes'
          },
          {
            title: 'SETTINGS',
            path: '/company/settings'
          }
        ]
      },
      { 
        title: 'USERS',
        path: '/users',
        icon: <UsersIcon fontSize="small" />,
      },
      // {
      //   title: 'READERS',
      //   path: '/readers',
      //   icon: <SettingsRemoteIcon fontSize="small" />
      // }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Avatar
                src={localStorage.getItem("profilePath")}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {localStorage.getItem("fullName")}
              </Typography>
              {/* <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {"Premium"}
                </Link>
              </Typography> */}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => {
            if (section.roles.indexOf(localStorage.getItem("role")) == -1) {
                return("")
            }else{
            return(
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />)
            }
            })}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;


import React, { useCallback, useState, useEffect, createRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import Chart from 'react-apexcharts';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  TextField
} from '@material-ui/core';

import ChevronRightIcon from '../../icons/ChevronRight';
// import UserIcon from '../../icons/UserIcon';
// import LockIcon from '../../icons/LockIcon';

import axios from '../../lib/axios';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import moment from 'moment';
import ReportConfiguration from './ReportConfiguration';
import ShiftRevenueReportOverviewData from './ShiftRevenueReportOverviewData';

// const BorderLinearProgress = withStyles((theme) => ({
//   root: {
//     height: 20,
//     borderRadius: 2,
//   },
//   colorPrimary: {
//     backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
//   },
//   bar: {
//     borderRadius: 5,
//     backgroundColor: '#1a90ff',
//   },
// }))(LinearProgress);


const tabs = [
  { label: 'Report Overview', value: 'report overview' },
  // { label: 'Configuration', value: 'configuration' }
];

class ShiftRevenueReportDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: {},
      productionShiftData: {},
      arrayProductionShifts: [],
      arrayData: [],
      startDate: new Date(),
      endDate: new Date(),
      currentTab: "report overview"
    }

  }
  componentDidMount() {
    this.getShiftData()
  }

  getShiftData = () => {
    this.setState({ isLoading: true })
    axios.post(
      '/apis/machines/report/shiftperformancereport', {
      companyId: localStorage.getItem("publicID"),
      startDate: this.state.startDate,
      endDate: this.state.endDate
    }).then((res) => {
      if (res.data.success) {
        console.log("shiftoverview::Data:", res.data.data)
        var data = res.data.data

        this.setState({
          arrayData: data,
          // productionShiftData: productionShiftData,
          // arrayProductionShifts: productionShiftData.productionShifts,
          deleteConfirmationShouldOpen: false,
          isLoading: false,
          // currentTab: "shiftoverview"
        })
      }
    }).catch((error) => {
      this.setState({
        isLoading: false,
      })
      console.log(error);
    });
  }


  handleTabsChange = (event, value) => {

    // if(value == "shiftoverview"){
    //   this.getShiftData()
    // }else{
    this.setState({
      currentTab: value
    })
    // }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>ScanAware</title>
        </Helmet>
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 8
          }}
        >
          <Container maxWidth={'xl'}>
            <Grid
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Shift Revenue Report
                </Typography>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRightIcon fontSize="small" />}
                  sx={{ mt: 1 }}
                >
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard"
                    variant="subtitle2"
                  >
                    Dashboard
                </Link>
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/reports"
                    variant="subtitle2"
                  >
                    Report List
                </Link>

                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    Report Details
                </Typography>
                </Breadcrumbs>
              </Grid>

            </Grid>
            <Box sx={{ mt: 3 }}>

              <Tabs
                indicatorColor="primary"
                onChange={this.handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={this.state.currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
            </Box>
            <Divider />
            <Card sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid
                  sx={{ m: 1 }}
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item 
                    >
                      <DatePicker
                        label="Start Date"
                        value={this.state.startDate}
                        onChange={(newValue) => {
                          if (newValue <= this.state.endDate && moment.isDate(newValue)) {
                            this.setState({ startDate: moment(newValue).utc().toDate() }, () => {
                              this.getShiftData()
                            })
                          }
                        }}
                        renderInput={(params) => <TextField

                          helperText=""
                          {...params} />}
                      />
                      </Grid>
                      <Grid item 
                      >
                      <DatePicker
                        label="End Date"
                        value={this.state.endDate}
                        onChange={(newValue) => {

                          if (this.state.startDate <= newValue && moment.isDate(newValue)) {
                            this.setState({ endDate: moment(newValue).utc().toDate() }, () => {
                              this.getShiftData()
                            })
                          }
                        }}
                        renderInput={(params) => <TextField

                          helperText=""

                          {...params} />}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
            



              {this.state.currentTab === 'report overview' &&
                (
                  <ShiftRevenueReportOverviewData arrayData={this.state.arrayData} {... this.props} />
                )
              }
              {this.state.currentTab === 'configuration' && (
                <Box sx={{ py: 3 }}>
                  <ReportConfiguration arrayData={this.state.arrayProductionShifts} {...this.props} />
                </Box>
              )}
            </Card>
            <Backdrop style={{ zIndex: 1 }} open={this.state.isLoading} >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Container>
        </Box>
      </>
    );
  }
};

export default ShiftRevenueReportDetails;

import Chart from 'react-apexcharts';
import { Box, Table, TableRow, TableCell, TableHead, TableBody, Card, Typography,Collapse,IconButton } from '@material-ui/core';
import { alpha, useTheme } from '@material-ui/core/styles';
import ArrowRightIcon from '../../icons/ArrowRight';
import Scrollbar from '../../components/Scrollbar';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function Row(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
 // console.log("test",data)
  return (
    <React.Fragment>
      <TableRow>
      <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.totalTime}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.totalRunningTime}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.totalDownTime}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {Number(data.runtimeRevenue).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {Number(data.totalDownTimeCose).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {data.currentTemp}<sup style={{
                                  marginLeft: 4,
                                  color: '#000'
                                }}>°F</sup>
          </Typography>
        </TableCell>

      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 ,paddingLeft:0,paddingRight:0}} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ minWidth: 700 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ml:2}}>
                By Shifts
              </Typography>
              <Table size="small">
              <TableHead>
                <TableRow>
                <TableCell />
                  <TableCell>
                    
                    Name
                </TableCell>
                  <TableCell>
                    Total Time
                </TableCell>
                  <TableCell>
                    Runtime
                </TableCell>
                  <TableCell>
                    Downtime
                </TableCell>
                  <TableCell>
                    Runtime Revenue
                </TableCell>
                  <TableCell>
                    Downtime Cost
                </TableCell>
                  <TableCell sx={{visibility:'hidden'}}>
                    Current Temp
                </TableCell>

                </TableRow>
              </TableHead>
            
                <TableBody>
                {data.productionShifts.map((shift) => (
                    <TableRow key={shift._id}>
                      <TableCell>
                      <IconButton aria-label="expand row" size="small" sx={{visibility:'hidden'}}>
                        <KeyboardArrowUpIcon /> 
                      </IconButton>
                      </TableCell>
                      <TableCell>
                        {shift.name}
                      </TableCell>
                      <TableCell>
                        {shift.name}
                      </TableCell>
                      <TableCell>
                        {shift.runningTime}
                      </TableCell>
                      <TableCell>
                        {shift.downTime}
                      </TableCell>
                      <TableCell>
                        {Number(shift.runtimeRevenue).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </TableCell>
                      <TableCell>
                        {Number(shift.downTimeCost).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </TableCell>
                      <TableCell  sx={{visibility:'hidden'}}>
                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const MachineRevenueReportOverviewData = (props) => {
  const { arrayData, ...other } = props;
  const theme = useTheme();
  return (
    <Card {...other}>
      {/* <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      > */}

      <Box
        sx={{
          minHeight: '100%',

        }}
      >

        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell />
                  <TableCell>
                    Name
                </TableCell>
                  <TableCell>
                    Total Time
                </TableCell>
                  <TableCell>
                    Runtime
                </TableCell>
                  <TableCell>
                    Downtime
                </TableCell>
                  <TableCell>
                    Runtime Revenue
                </TableCell>
                  <TableCell>
                    Downtime Cost
                </TableCell>
                  <TableCell>
                    Current Temp
                </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {arrayData.map((data) => 
                  <Row key={data._id} data={data} />
                )
                }
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Box >
    </Card>
  );
}
export default MachineRevenueReportOverviewData;
